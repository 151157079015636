import React, { FC, memo } from 'react';
import { useDrop } from 'react-dnd';
import { AnchorIcon } from '@anchor-ds/react';
import { BasketHeader, IStringIndex } from './util';
import { DustbinState } from './Basket.component';
import { IBasket } from './Configurator.types';
import { OnsiteCard } from './OnsiteCard.component';

export interface OnsiteProps {
  groupedBasket: IStringIndex;
  dustbin: DustbinState;
  deleteItem: (bid: string) => void;
  onDrop: (item: IBasket[]) => void;
  defbasket: IBasket[];
  equipment: string;
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
}
export const Onsite: FC<OnsiteProps> = memo(
  ({
    groupedBasket,
    dustbin,
    deleteItem,
    onDrop,
    equipment,
    defbasket,
    setBasket,
       setUnsaved,
  }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: dustbin.accepts,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = '#fff';
    if (isActive) {
      backgroundColor = '#8DC4FF';
    } else if (canDrop) {
      backgroundColor = '#EDEFF0';
    }
    return (
      <div style={{ backgroundColor, padding: '5px' }} ref={drop}>
        <BasketHeader>
          <AnchorIcon name="landmark-filled" size="16" /> Onsite Scope
        </BasketHeader>
        <div style={{ minHeight: '10vh' }}>
          {equipment !== 'summary' &&
            groupedBasket.Onsite &&
            equipment &&
            groupedBasket.Onsite[equipment]
              ? Object.values(groupedBasket.Onsite[equipment]).map(
                  (equipmentId, index) => (
                    <OnsiteCard
                      index={index}
                      basket={equipmentId}
                      deleteItem={deleteItem}
                      defaultBasket={defbasket}
                      setBasket={setBasket}
                      setUnsaved={setUnsaved}
                      isEditable={equipment !== "summary"}
                    />
                  )
                )
              : null}
          {equipment === 'summary' && groupedBasket.Onsite
              ? Object.keys(groupedBasket.Onsite).map((equ) => (
                  <div style={{ borderLeft: '2px solid #DEE1E5' }}>
                    <span
                      style={{
                        fontSize: '12px',
                        backgroundColor: '#DEE1E5',
                        padding: '4px',
                      }}>
                      {equ}
                    </span>
                    {Object.values(groupedBasket.Onsite[equ]).map(
                      (equipmentId, index) => (
                        <OnsiteCard
                          index={index}
                          basket={equipmentId}
                          deleteItem={deleteItem}
                          defaultBasket={defbasket}
                          setBasket={setBasket}
                          setUnsaved={setUnsaved}
                          isEditable={equipment !== "summary"}
                        />
                      )
                    )}
                  </div>
                ))
              : null}
        </div>
      </div>
    );
  }
);
