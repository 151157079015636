import {
  AnchorExpansionPanel,
  AnchorIcon, AnchorList, AnchorListItem,
  AnchorMessage,
  AnchorModal,
  AnchorSpacing,
  AnchorSpinner,
  AnchorStatusIndicator,
  AnchorSurface,
  AnchorTimeline,
  AnchorTimelineEvent,
  AnchorTimelinePoint,
  AnchorToggle,
  AnchorTooltip,
  TimelineEvent,
} from '@anchor-ds/react';
import React, {createRef, RefObject, useEffect, useState} from 'react';
import axios from 'axios';
import {AnchorIconName, AnchorTimelineCustomEvent} from '@anchor-ds/core';
import styled from 'styled-components';
import moment from 'moment/moment';
import {IEquipment} from '../../pages/Index/App.types';
import {useAuth} from '../../features/auth/auth.hooks';
import {IEvent, IEventData, IRecommendation, IRequirement, ISalesOrder, IServiceOrder,} from './Equipment.types';
import {IFilters} from '../Installation/Installation.component';
import {LineItemTable} from './LineItemTable';

interface EquipmentProps {
  equipment: IEquipment;
  fetchEvents(id: string, year: number): Promise<IEventData>;
  filters: IFilters;
  year: number;
  month: number;
  slider: number;
}

const Wrapper = styled.div`
  margin-left: 20px;
  display: flex;
  width: 80%;
  gap: 4px;
  align-items: center;
  --ds-timeline-tick-label-font-size: 1px;
  anchor-timeline {
    background-color: #dee1e5;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const getIcon = (type?: string): AnchorIconName => {
  if (type) {
    switch (type) {
      case 'Service Advance Ship':
      case 'CS ICB sales order':
        return 'service-advance';
        break;
      case 'Service Intercompany':
        return 'service-intercompany';
        break;
      case 'WT Free of Charge':
        return 'service-free';
        break;
      case 'Std order I/C autom.':
      case 'WT templ. Std order':
        return 'service-standard';
        break;
      case '050 Overhaul/Maintenance':
        return 'maintenance';
        break;
      case '054 Troubleshooting':
        return 'troubleshooting';
        break;
      case '052 Repair':
        return 'repair';
        break;
      case '223 Rework on site':
        return 'rework-on-site';
        break;
      default:
        return 'placeholder';
    }
  }
  return 'placeholder';
};

export const iconArray = [
  '052 Repair',
  '223 Rework on site',
  '054 Troubleshooting',
  '050 Overhaul/Maintenance',
];

export const getIconFromDesc = (desc?: string): string => {
  // eslint-disable-next-line consistent-return,array-callback-return
  if (desc) {
    const iconF = iconArray.find((icon) => desc.includes(icon));
    if (iconF) {
      return iconF;
    }
  }
  return 'none';
};

export const icons = (ev: IEvent): AnchorIconName => {
  if (ev.source) {
    switch (ev.source) {
      case 'Recommendation':
        return 'measure';
        break;
      case 'Order':
        return getIcon(ev.event_subtype)
        break;
      default:
        return getIcon(getIconFromDesc(ev.description));
    }
  }
  return 'placeholder';
};

interface IStringIndex {
  [key: string]: IRequirement[];
};

export const EquipmentComponent = ({
  equipment,
  fetchEvents,
  filters,
  year,
  month,
  slider,
}: EquipmentProps): JSX.Element => {
  const { accessToken, config } = useAuth();
  const [isVisible, setVisible] = useState(true);
  const [eventData, setEventData] = useState<IEventData | undefined>(undefined);
  const [soModalActive, setSoModalActive] = useState(false);
  const modalRef: RefObject<HTMLAnchorModalElement> =
    createRef<HTMLAnchorModalElement>();
  const [soSpnId, setSpnId] = useState<string>('');
  const [soId, setSoId] = useState<string>('');
  const [soRecId, setSoRecId] = useState<string>('');
  const [selectedEvent, setSE] = useState<IEvent | undefined>(undefined);
  const [orderData, setOrderData] = useState<IServiceOrder | undefined>(
    undefined
  );
  const sPartModalRef: RefObject<HTMLAnchorModalElement> =
    createRef<HTMLAnchorModalElement>();
  const recModalRef: RefObject<HTMLAnchorModalElement> =
      createRef<HTMLAnchorModalElement>();
  const [recModalActive, setRecModalActive] = useState(false);
  const [partModalActive, setPartModalActive] = useState(false);
  const [partOrderData, setPartOrderData] = useState<ISalesOrder | undefined>(
    undefined
  );
  const [recData, setRecData] = useState<IRecommendation | undefined>(
      undefined
  );
  const [groupedReq, setGroupedReq] = useState<IStringIndex>(
      {}
  );
  const [start, setStart] = useState<Date>(new Date(year - 1, 10, 30));
  const [end, setEnd] = useState<Date>(new Date(year, 12, 31));

  const styles = {
    '--ds-timeline-tick-label-font-size': '1rem',
    '--ds-timeline-tick-label-line-height': '1rem',
    '--ds-timeline-bar-color-background': '#e9e9e9',
    '--ds-timeline-tick-major-height': '10px',
    '--ds-tooltip-font-size': '1px',
    '--ds-tooltip-line-height': '10px',
    '--ds-tooltip-max-width': '2000px',
    '--ds-timeline-supplemental-icon-color-fill-slot-1': '#059918',
    '--ds-timeline-supplemental-icon-color-fill-slot-2': '#c76d00',
    '--ds-timeline-supplemental-icon-color-fill-slot-3': '#059918',
  };

  const fetchDetails = async (id: string): Promise<IServiceOrder> =>
    axios
      .get<IServiceOrder>(
        `${config.REACT_APP_API_BASE_URL}/tsb/service-order-details/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => response.data);

  const fetchOrderDetails = async (id: string): Promise<ISalesOrder> =>
    axios
      .get<ISalesOrder>(
        `${config.REACT_APP_API_BASE_URL}/tsb/order-details/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => response.data);

  const fetchRecDetails = async (id: string): Promise<IRecommendation> =>
      axios
          .get<IRecommendation>(
              `${config.REACT_APP_API_BASE_URL}/tsb/recommendation/${id}`,
              { headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data);

  useEffect(() => {
    const today = new Date();
    switch (slider) {
      case 7:
        setStart(new Date(year, month - 1, 1));
        setEnd(new Date(year, month - 1, 31));
        break;
      case 6:
        setStart(new Date(year, month - 2, today.getDay()));
        setEnd(new Date(year, month + 1, today.getDay()));
        break;
      case 5:
        setStart(new Date(year, month - 3, today.getDay()));
        setEnd(new Date(year, month + 2, today.getDay()));
        break;
      case 4:
        setStart(new Date(year, month - 5, today.getDay()));
        setEnd(new Date(year, month + 4, today.getDay()));
        break;
      case 3:
        setStart(new Date(year - 1, 10, 30));
        setEnd(new Date(year, 12, 31));
        break;
      case 2:
        setStart(new Date(year - 2, 10, 30));
        setEnd(new Date(year + 1, 12, 31));
        break;
      case 1:
        setStart(new Date(year - 3, 10, 30));
        setEnd(new Date(year + 2, 12, 31));
        break;
      default:
        break;
    }
  }, [slider, year, month]);

  useEffect(() => {
    if (accessToken && equipment.id) {
      fetchEvents(equipment.equipment_id, year).then((resp) => {
        setEventData(resp);
      });
    }
  }, [equipment.id, year]);

  useEffect(() => {
    if (accessToken && soId !== '') {
      fetchDetails(soId).then((resp) => {
        setOrderData(resp);
      });
    }
  }, [soId, year]);

  useEffect(() => {
    if (accessToken && soSpnId) {
      fetchOrderDetails(soSpnId).then((resp) => {
        setPartOrderData(resp);
      });
    }
  }, [soSpnId, year]);

  useEffect(() => {
    if (accessToken && soRecId) {
      fetchRecDetails(soRecId).then((resp) => {
        setRecData(resp);
        const groupedReqs: IStringIndex = {};
        resp?.service_requirements?.forEach((req: IRequirement) => {
          if (!Object.keys(groupedReqs).includes(req.has_section.sectionId)) {
            groupedReqs[req.has_section.sectionId] = [];
          }

          groupedReqs[req.has_section.sectionId].push({
            ...req,
          });

          setGroupedReq(groupedReqs);
        });
      });
    }
  }, [soRecId, year]);

  useEffect(() => {
    if (soId !== '') {
      setSoModalActive(true);
      const findEvent = eventData?.event.find(
        (e) => e.event_source[0] && e.event_source[0].id === soId
      );
      if (findEvent) {
        setSE(findEvent);
      }
    }
  }, [soId]);

  useEffect(() => {
    if (!soModalActive) {
      setSoId('');
      setOrderData(undefined);
      setSE(undefined);
    }
  }, [soModalActive]);

  useEffect(() => {
    if (soSpnId !== '') {
      setPartModalActive(true);
      const findEvent = eventData?.event.find((e) =>
        e.event_source[0] ? e.event_source[0].id === soSpnId : undefined
      );
      if (findEvent) {
        setSE(findEvent);
      }
    }
  }, [soSpnId]);

  useEffect(() => {
    if (soRecId !== '') {
      setRecModalActive(true);
      const findEvent = eventData?.event.find((e) =>
          e.event_source[0] ? e.event_source[0].id === soRecId : undefined
      );
      if (findEvent) {
        setSE(findEvent);
      }
    }
  }, [soRecId]);

  useEffect(() => {
    if (!partModalActive) {
      setSpnId('');
      setPartOrderData(undefined);
      setSE(undefined);
    }
  }, [partModalActive]);

  useEffect(() => {
    if (!recModalActive) {
      setSoRecId('');
      setRecData(undefined);
      setSE(undefined);
    }
  }, [recModalActive]);

  // eslint-disable-next-line consistent-return
  const filteredData = (
    data: IEvent[],
    filter: IFilters
    // eslint-disable-next-line consistent-return
  ): IEvent[] | undefined => {
    if (filter.showOrders && !filter.showSOrders) {
      return data.filter((ev) => ev.source === 'Order');
    }
    if (!filter.showOrders && filter.showSOrders) {
      return data.filter((ev) => ev.source === 'ServiceOrder');
    }
    if (!filter.showOrders && !filter.showSOrders) {
      return [];
    }
    if (filter.showOrders && filter.showSOrders) {
      return data;
    }
  };
  return (
    <><AnchorSpacing name="compact">
      {partModalActive && !partOrderData && <AnchorModal active={partModalActive && partOrderData === undefined}><AnchorSpinner size="24" /></AnchorModal>}
      {soModalActive && !orderData && <AnchorModal active={soModalActive && orderData === undefined}><AnchorSpinner size="24" /></AnchorModal>}
      {recModalActive && !recData && <AnchorModal active={recModalActive && recData === undefined}><AnchorSpinner size="24" /></AnchorModal>}
      <br />
      <br />
      <AnchorModal
          key={`${recModalRef}Order`}
          active={recModalActive && recData !== undefined}
          closeOnOverlayClick
          onModalClose={() => setRecModalActive(false)}
          ref={recModalRef}>
        {recData && (
            <>
              <div
                  slot="heading"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}>
                <ModalWrapper>
                  <span>Event Details - {equipment.nickname}</span>
                  <AnchorIcon
                      name="measure" margin="none"
                      size="24"
                  />
                  <AnchorTooltip
                      content={selectedEvent ? selectedEvent.description : ''}>
                    <span>SCHEDULED MAINTENANCE</span>
                  </AnchorTooltip>
                </ModalWrapper>
                <ModalWrapper>
                  <AnchorStatusIndicator
                      label={recData.status}
                      mode="light-label"
                  />
                </ModalWrapper>
              </div>
              <div className="ds-container--24">
                <div className="ds-row">
                  {Object.values(groupedReq).map((val) => (<AnchorList style={{width: "30%"}}>
                          <AnchorListItem style={{color: "black", fontWeight: "bold"}} disabled>{`${val[0].has_section.sectionId} ${val[0].has_section.description}`}</AnchorListItem>
                          {val.map((req) => (<AnchorListItem>{req.name}
                            {req.description ? <AnchorTooltip content={req.description}>
                            <AnchorIcon name="information" size="16"/>
                          </AnchorTooltip>: null}</AnchorListItem>))}
                    </AnchorList>
                    ))}
                </div>
              </div>
              <AnchorExpansionPanel header="Event Data">
                <pre>{JSON.stringify(selectedEvent, undefined, 2)}</pre>
              </AnchorExpansionPanel>
              <AnchorExpansionPanel header="Data">
                <pre>{JSON.stringify(recData, undefined, 2)}</pre>
              </AnchorExpansionPanel>
            </>
        )}
      </AnchorModal>
      <AnchorModal
        key={`${sPartModalRef}Order`}
        active={partModalActive && partOrderData !== undefined}
        closeOnOverlayClick
        onModalClose={() => setPartModalActive(false)}
        ref={sPartModalRef}>
        {partOrderData && (
          <>
            <div
              slot="heading"
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}>
              <Wrapper>
                <span>Event Details - {equipment.nickname}</span>
                <AnchorIcon
                  name={
                    selectedEvent
                      ? getIcon(selectedEvent.event_subtype)
                      : 'placeholder'
                  }
                  margin="none"
                  size="24"
                />
                <AnchorTooltip
                  content={selectedEvent ? selectedEvent.description : ''}>
                  <span>SPARE PART ORDER</span>
                </AnchorTooltip>
              </Wrapper>
              <Wrapper>
                <AnchorStatusIndicator
                  label="#datamissing"
                  mode="light-label"
                />
                <span>SPARE PART ORDER</span>
              </Wrapper>
            </div>
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-8" />
                <div className="ds-col-8">Status</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-4">
                  <span className="ds-color-text-helper">
                    {partOrderData.id?.split('-')[0]}
                  </span>
                </div>
                <div className="ds-col-4">Spare Part Order</div>
                <div className="ds-col-4">#data missing</div>
                <div className="ds-col-4">Quotation Day</div>
                <div className="ds-col-4">{partOrderData.po_date}</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-4" />
                <div className="ds-col-4">{partOrderData.sd_doc_type_desc}</div>
                <div className="ds-col-4">#data missing</div>
                <div className="ds-col-4">Quotation Number</div>
                <div className="ds-col-4">{partOrderData.reference_doc}</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-4" />
                <div className="ds-col-4" />
                <div className="ds-col-4" />
                <div className="ds-col-4">Customer Reference</div>
                <div className="ds-col-4">{partOrderData.po_number}</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-4" />
                <div className="ds-col-4" />
                <div className="ds-col-4" />
                <div className="ds-col-4">Order reason</div>
                <div className="ds-col-4">
                  {partOrderData.sd_order_reason_desc}
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-12">
                  <span className="ds-text-helper">Deliveries</span>
                  <AnchorSurface tier={1}>
                    <div className="ds-container">
                      <div className="ds-row">
                        <div className="ds-col-4">
                          #data missing for Deliveries
                        </div>
                      </div>
                    </div>
                  </AnchorSurface>
                </div>
                <div className="ds-col-12">
                  <span className="ds-text-helper">Line items</span>
                  <LineItemTable lines={partOrderData.contains_lines} />
                </div>
              </div>
            </div>
            <AnchorExpansionPanel header="Event Data">
              <pre>{JSON.stringify(selectedEvent, undefined, 2)}</pre>
            </AnchorExpansionPanel>
            <AnchorExpansionPanel header="Data">
              <pre>{JSON.stringify(partOrderData, undefined, 2)}</pre>
            </AnchorExpansionPanel>
          </>
        )}
      </AnchorModal>
      <AnchorModal
        key={`${modalRef}ServiceOrder`}
        active={soModalActive && orderData !== undefined}
        closeOnOverlayClick
        onModalClose={() => setSoModalActive(false)}
        ref={modalRef}>
        {orderData && (
          <>
            <div
              slot="heading"
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}>
              <ModalWrapper>
                <span>Event Details - {equipment.nickname}</span>
                <AnchorIcon
                  name={
                    selectedEvent
                      ? getIcon(getIconFromDesc(selectedEvent.description))
                      : 'placeholder'
                  }
                  margin="none"
                  size="24"
                />
                <span>{orderData.pm_activity_type}</span>
              </ModalWrapper>
              <ModalWrapper>
                <AnchorStatusIndicator
                  label={orderData.status}
                  mode="light-label"
                />
                <span>{orderData.sap_notification_type}</span>
              </ModalWrapper>
            </div>
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-6" />
                <div className="ds-col-6">Status</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-3">
                  <span className="ds-color-text-helper">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://wartsila--test.sandbox.lightning.force.com/${orderData.id}`}>
                      {orderData.name ? orderData.name : ''}
                    </a>
                  </span>
                </div>
                <div className="ds-col-3">{orderData.description_of_work}</div>
                <div className="ds-col-3">{orderData.status}</div>
                <div className="ds-col-3">Date</div>
                <div className="ds-col-3">
                  {moment(orderData.createddate).format('L')}
                </div>
                <div className="ds-col-3" />
                <div className="ds-col-3" />
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-3" style={{ marginLeft: '5px' }}>
                  {orderData.child_order[0]?.name}
                </div>
                <div className="ds-col-3">
                  {orderData.child_order[0]?.description_of_work}
                </div>
                <div className="ds-col-3" style={{ marginLeft: '-5px' }}>
                  {orderData.child_order[0]?.status}
                </div>
                <div className="ds-col-3">Customer PO Number</div>
                <div className="ds-col-3">{orderData.po_number}</div>
                <div className="ds-col-3">SAP Order ID</div>
                <div className="ds-col-3">{orderData.sap_order_id}</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3">SAP Order Status</div>
                <div className="ds-col-3">{orderData.sap_order_status}</div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3">Start</div>
                <div className="ds-col-3">
                  {moment(orderData.customer_requested_date).format('L')}
                </div>
              </div>
            </div>
            <br />
            <div className="ds-container--24">
              <div className="ds-row">
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3" />
                <div className="ds-col-3">End</div>
                <div className="ds-col-3">
                  {moment(orderData.finish_date).format('L')}
                </div>
              </div>
            </div>
            <br />
            {orderData.has_serviceworkreport.map((swr) => (
              <>
                <AnchorSurface tier={1}>
                  <div className="ds-container">
                    <div className="ds-row">
                      <div className="ds-col-4">
                        {swr.name}
                      </div>
                      <div className="ds-col-7">{swr.work_type}</div>
                      <div className="ds-col-1">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://tkb.wartsila.com/?doc=${swr.name}`}>
                          <AnchorIcon
                              name="new-window"
                              margin="none"
                              size="12"
                          />{' '}
                          TKB
                        </a>{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://wartsila--test.sandbox.lightning.force.com/${swr.id}`}>
                          <AnchorIcon
                              name="new-window"
                              margin="none"
                              size="12"
                          />{' '}
                          CRM
                        </a>
                      </div>
                    </div>
                    <div className="ds-row" style={{ marginLeft: '5px', height: "20px", fontSize: "12px" }}>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-col-2">
                        {swr.reason_for_service}
                      </div>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-text-helper ds-col-2">
                        {moment(swr.malfunction_start_date).format('L')}
                      </div>
                      <div className="ds-col-7" style={{fontSize: "12px" }}>
                        {swr.recommendations_for_customer ? 'Recommendations' : ''}
                      </div>
                    </div>
                    <div className="ds-row" style={{ marginLeft: '5px', height: "20px", fontSize: "12px" }}>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-2">
                        FS Coordinator
                      </div>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-text-helper ds-col-2">
                        {orderData?.fsm_coordinator_name}
                      </div>
                      <div className="ds-col-7" style={{fontSize: "12px" }}>
                          {swr.recommendations_for_customer ? swr.recommendations_for_customer : ''}
                      </div>
                    </div>
                    <div className="ds-row" style={{ marginLeft: '5px', height: "20px", fontSize: "12px" }}>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-2">Duration</div>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-text-helper ds-col-2">
                        {`${moment(swr.malfunction_start_date).format(
                          'L'
                        )} - ${moment(swr.malfunction_end_date).format('L')}`}
                      </div>
                    </div>
                    <div className="ds-row" style={{ marginLeft: '5px', height: "20px", fontSize: "12px" }}>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-2">
                        Running (hours)
                      </div>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-text-helper ds-col-2">
                        {swr.engine_running_hours_details
                          ? swr.engine_running_hours_details
                          : '@ N/A'}
                      </div>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-8">
                        {swr.conclusions && swr.conclusions !== "◊" ? 'Conclusions' : ''}
                      </div>
                    </div>
                    <div className="ds-row" style={{ marginLeft: '5px', height: "20px", fontSize: "12px" }}>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-2">Status</div>
                      <div style={{height: "20px", fontSize: "12px", fontWeight: "bold"}} className="ds-text-helper ds-col-2">
                        {swr.status}
                      </div>
                      <div style={{height: "20px", fontSize: "12px"}} className="ds-text-helper ds-col-8">
                        {swr.conclusions && swr.conclusions !== "◊" ? swr.conclusions : ''}
                      </div>
                    </div>
                  </div>
                </AnchorSurface>
                <br />
              </>
            ))}
            <AnchorExpansionPanel header="Event Data">
              <pre>{JSON.stringify(selectedEvent, undefined, 2)}</pre>
            </AnchorExpansionPanel>
            <AnchorExpansionPanel header="Order Data">
              <pre>{JSON.stringify(orderData, undefined, 2)}</pre>
            </AnchorExpansionPanel>
          </>
        )}
      </AnchorModal>
      <div
        key={equipment.id}
        className="ds-row"
        style={{ alignItems: 'center' }}>
        <AnchorIcon name="draggable" size="24" />
        <AnchorToggle
          onToggleChange={() => setVisible(!isVisible)}
          checked={isVisible}
          name="test"
        />
        <AnchorTooltip placement="bottom-start" content-sized="false">
          <AnchorMessage type="default" leading-icon="engine">
            {equipment.nickname}
          </AnchorMessage>
          <div slot="content">
            {equipment.running_hours} h
            <br />
            {equipment.equipment_id}
            <br />
            {equipment.product_number}
            <br />
            {equipment.type_extension}
            <br />
          </div>
        </AnchorTooltip>
        {!eventData && <AnchorSpinner size="24" />}
        {isVisible && eventData && (
          <Wrapper>
            <AnchorTimeline
              start={start.toISOString()}
              end={end.toISOString()}
              style={styles}
              onTimelineEventClick={(
                event: AnchorTimelineCustomEvent<TimelineEvent>
              ) => {
                const evvent = eventData?.event.find(
                  (ev) => ev.description === event.detail.label
                );
                if (evvent && evvent.source === 'ServiceOrder') {
                  setSoId(evvent.event_source[0].id);
                } else if (evvent && evvent.source === 'Order') {
                  setSpnId(evvent.event_source[0].id);
                } else if (evvent && evvent.source === 'Recommendation') {
                  setSoRecId(evvent.event_source[0].id);
                }
              }}>
              {filteredData(eventData.event, filters)?.map((ev) => (
                <AnchorTimelineEvent
                  a-key={
                    ev && ev.event_source && ev.event_source[0]
                      ? ev.event_source[0].id
                      : 'none'
                  }
                  start={ev.event_date}
                  icon={icons(ev)}
                  label={ev.description}
                  sIcon1={
                    ev.source === 'ServiceOrder' &&
                    Math.floor(Math.random() * 100) > 49
                      ? 'swr'
                      : undefined
                  }
                  sIcon2={
                    ev.source === 'ServiceOrder' &&
                    Math.floor(Math.random() * 100) > 49
                      ? 'swr'
                      : undefined
                  }
                  sIcon3={
                    ev.source === 'ServiceOrder' &&
                    Math.floor(Math.random() * 100) > 49
                      ? 'change'
                      : undefined
                  }
                />
              ))}
              <AnchorTimelinePoint date={new Date().toISOString()} label="Today"/>
            </AnchorTimeline>
          </Wrapper>
        )}
      </div></AnchorSpacing>
    </>
  );
};
