import React, {useState} from 'react';
import {AnchorSpacing, AnchorSpinner} from "@anchor-ds/react";
import axios from "axios";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ToolbarComponent} from "../../components/Toolbar/Toolbar.component";
import {InstallationComponent} from "../../components/Installation/Installation.component";
import {IInstallation} from "./App.types";
import {useAuth} from "../../features/auth/auth.hooks";
import {Configurator} from "../Configurator/Configurator.component";
import './App.css';

export default function App(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [data, setInstallations] = useState<IInstallation | undefined>(undefined);
  const { accessToken, config } = useAuth();

  const fetchData = async (id: string): Promise<void> => {
    setLoading(true);
    const req = await axios
        .get<IInstallation>(
            `${config.REACT_APP_API_BASE_URL}/tsb/installation/${id}`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data);
    setInstallations(req);
    setLoading(false);
  };

  if(!accessToken) return <><AnchorSpinner size="24" /></>;

  return (<>
    <Router>
      <AnchorSpacing name="compact" viewDistance={3}>
        <Routes>
          <Route element={<ToolbarComponent name={data?.name ? data?.name : ""} isLoading={isLoading} fetchData={fetchData}/>} >
            <Route index element={<></>} />
            <Route path="/:id?" element={<InstallationComponent data={data} isLoading={isLoading} fetchData={fetchData}/>}/>
          </Route>
          <Route path="/:id/configurator/:offer?" element={<Configurator/>} />
        </Routes>
      </AnchorSpacing>
    </Router>
  </>);
}
