import React, { FC, memo } from 'react';
import { AnchorExpansionPanel } from '@anchor-ds/react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './util';
import { IBasket } from './Configurator.types';
import { Card } from './Card.component';

export interface WorkshopCardProps {
  index: number;
  basket: IBasket[];
  deleteItem: (bid: string) => void;
  defaultBasket: IBasket[];
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  isEditable: boolean;
}
export const WorkshopCard: FC<WorkshopCardProps> = memo(
  ({ index, basket, deleteItem, defaultBasket, setBasket, setUnsaved, isEditable}) => {
    const [{ opacity }, drag, dragPreview] = useDrag(
      () => ({
        type: ItemTypes.WORKSHOP,
        item: basket,
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [basket]
    );
    return (
      <div
        style={{ transform: 'translate3d(0, 0, 0)', opacity }}
        ref={dragPreview}>
        {index !== 0 ? <hr /> : null}
        <div ref={isEditable ? drag : null}>
          <AnchorExpansionPanel
            header={`${basket[0].mainsectionid} ${basket[0].mainsectionname}`}>
            {basket
              .filter((e) => e.basketType === 'Workshop')
              .map((b) => (
                <Card
                  b={b}
                  deleteItem={deleteItem}
                  defaultBasket={defaultBasket}
                  setBasket={setBasket}
                  setUnsaved={setUnsaved}
                  isEditable={isEditable}
                />
              ))}
          </AnchorExpansionPanel>
        </div>
      </div>
    );
  }
);
