import React, {useEffect, useState} from 'react';
import {
  AnchorButton,
  AnchorCard,
  AnchorExpansionPanel,
  AnchorIcon,
  AnchorInput,
  AnchorInputEvent,
  AnchorList,
  AnchorListItem,
  AnchorSpacing,
  AnchorTabPanel,
  AnchorTabs,
  AnchorToggle,
  AnchorTooltip,
} from '@anchor-ds/react';
import {generatePath, Outlet, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {AnchorInputCustomEvent, AnchorTabsCustomEvent} from '@anchor-ds/core';
import {useAuth} from '../../features/auth/auth.hooks';
import {IBasket, IOffer, IRequirement, ISave, ISection, IServiceRequirement,} from './Configurator.types';
import './Configurator.css';
import {
  Disclaimer,
  Info,
  IStringIndex,
  Loader,
  SectionCard,
  SectionWrap,
  SelectButtons,
  SelectionButton,
  Wrapper,
} from './util';
import {Basket} from './Basket.component';

export const Configurator = (): JSX.Element => {
  const navigate = useNavigate();
  const { id, offer } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isSectionsLoading, setSectionsLoading] = useState(false);
  const [isRequirementsLoading, setReqLoading] = useState(false);
  const [isServicesSelected, setServices] = useState(false);
  const [cylinderCount, setCylCount] = useState(1);
  const [selectedProduct, setProduct] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedSection, setSection] = useState('');
  const [activeTab, setTab] = useState('Scheduled Maintenance');
  const [selectedRow, setRow] = useState('');
  const { accessToken, config } = useAuth();
  const [basket, setBasket] = useState<IBasket[]>([]);
  const [groupedBasket, setGBasket] = useState<IStringIndex>({});
  const [data, setData] = useState<IOffer | undefined>(undefined);
  const [isBasketLoading, setBLoad] = useState(false);
  const [isUnsaved, setUnsaved] = useState('unchanged');
  const [sectionData, setSectionData] = useState<ISection | undefined>(
    undefined
  );
  const [requirements, setRequirements] = useState<
    IServiceRequirement[] | undefined
  >(undefined);
  const [visibleRequirements, setVisRequirements] = useState<
    IRequirement[] | undefined
  >(undefined);
  const [visibleSections, setVisSections] = useState<ISection | undefined>(
    undefined
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLAnchorInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      const idInsty = (event.target as HTMLInputElement).value;
      if (idInsty && id) {
        setSearchValue(idInsty);
        setBasket([]);
        navigate(
          generatePath('/:id/configurator/:offer', { id, offer: idInsty })
        );
      }
    }
  };

  const searchStyles = {
    '--ds-input-border-radius': '15px',
    '--ds-input-width': '250px',
    textTransform: 'uppercase',
  };
  const toggleStyle = {
    '--ds-toggle-font-size': '12px',
  };

  const save = async (): Promise<void> => {
    if (selectedProduct !== 'summary' && selectedProduct !== 'notselected') {
      const saveData = {
        offer_configuration_id: offer,
        equipment_id: selectedProduct,
        data: basket,
      };
      await axios
        .post<IOffer>(
          `${config.REACT_APP_API_BASE_URL}/storage/configuration`,
          saveData,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => response.data);
      setUnsaved('unchanged');
    }
  };

  const fetchSave = async (): Promise<void> => {
    setBLoad(true);
    const req = await axios
      .get<ISave>(
        `${config.REACT_APP_API_BASE_URL}/storage/configuration/${offer}/${selectedProduct}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => response.data).catch((r) => r);
    if (req?.data?.length > 0) {
      setBasket(req.data);
    } else {
      setBLoad(false);
    }
  };

  const fetchSummary = async (): Promise<void> => {
    setBLoad(true);
    const req = await axios
      .get<ISave[]>(
        `${config.REACT_APP_API_BASE_URL}/storage/configuration/${offer}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => response.data);
    const newBa: IBasket[] = [];
    req.forEach((r) => {
      if(r && Array.isArray(r.data)) {
        r.data.forEach((b) => {
          if(newBa.findIndex((ba) => ba.id === b.id && ba.equipment === b.equipment && ba.basketType === b.basketType) === -1) {
            newBa.push(b);
          }
        });
      }
    });
    setBasket(newBa);
  };

  const fetchData = async (offerId: string): Promise<void> => {
    setLoading(true);
    const req = await axios
      .get<IOffer>(`${config.REACT_APP_API_BASE_URL}/tsb/offer/${offerId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => response.data)
      .catch((r) => r);
    if (req.id) {
      setData(req);
    }

    setLoading(false);
  };

  const fetchSections = async (): Promise<void> => {
    setSectionsLoading(true);
    const reftype = data?.configuration_object.find(
      (e) => e.product_number === selectedProduct
    )?.type;
    if (reftype) {
      const req = await axios
        .get<ISection>(
          `${config.REACT_APP_API_BASE_URL}/tsb/sections/${reftype}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => response.data);

      setSectionData(req);
      setVisSections(req);
    }
    setSectionsLoading(false);
  };

  const fetchServiceRequirements = async (): Promise<void> => {
    setRow('');
    setReqLoading(true);
    const reftype = data?.configuration_object.find(
      (e) => e.product_number === selectedProduct
    )?.type;
    if (reftype) {
      const req = await axios
        .get<IServiceRequirement[]>(
          `${config.REACT_APP_API_BASE_URL}/tsb/service-requirements/${reftype}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => response.data);

      setRequirements(req);
    }
    setReqLoading(false);
  };

  const handleBackNav: React.MouseEventHandler = () => {
    if (
      id &&
      window.confirm(
        'If you leave this page without saving, the basket will be emptied. Are you sure you want to continue?'
      )
    ) {
      navigate(generatePath('/:id', { id }));
    }
  };
  const handleServiceSelect: React.MouseEventHandler = () => {
    if (selectedProduct === '') {
      setProduct('notselected');
    } else if (selectedProduct !== 'notselected') {
      setServices(!isServicesSelected);
    }
  };

  const addToBasket: React.MouseEventHandler = () => {
    if (selectedRow !== '') {
      const req: IRequirement | undefined = visibleRequirements?.find(
        (r) => r.id === selectedRow
      );
      console.log(req);
      const hasItem = basket.find(
        (b) => b.id === req?.id && b.equipment === selectedProduct
      );
      if (req && !hasItem) {
        let newType: 'Workshop' | 'Optional' | 'Onsite';
        if (activeTab === 'Scheduled Maintenance') {
          newType = req.wscode ? 'Workshop' : 'Onsite';
        } else {
          newType = 'Optional';
        }
        console.log(req);
        const workcard = req?.has_workcard[0] ? req?.has_workcard[0] : {
          id: "",
          type: "",
          name: "",
          workshopcode: "",
          description: "",
          longdescription: "",
          uses_sparepart: [],
          contains: [],
          requires_skill: [],
        }
        const newBasketItem: IBasket = {
          ...req,
          basketType: newType,
          cylinderCount,
          equipment: selectedProduct,
          has_workcard: [
            {
              ...workcard,
              isSelected: true,
              responsible: false,
            },
          ],
        };
        const baskets: IBasket[] = [...basket, newBasketItem];
        setBasket(baskets);
        setUnsaved('true');
      }
    }
  };

  const deleteItem = (bid: string): void => {
    if (selectedProduct !== 'summary' && selectedProduct !== 'notselected') {
      const item = basket.find((b) => b.id === bid);
      if (id && item) {
        const newBasket: IBasket[] = basket.filter((i) => i.id !== bid);
        setBasket(newBasket);
        setUnsaved('true');
      }
    }
  };

  const switchTab = ({
    event,
  }: {
    event: AnchorTabsCustomEvent<{ panelId: string }>;
  }): void => {
    if (event.detail.panelId === 'Bulletins') {
      setTab('Bulletins');
      setVisRequirements(
        requirements
          ?.filter((req) => req.name === 'Bulletins')[0]
          .has_requirements.filter((r) => r.mainsectionid === selectedSection)
      );
    } else {
      setTab('Scheduled Maintenance');
      setVisRequirements(
        requirements
          ?.filter((req) => req.name === 'Scheduled Maintenance')[0]
          .has_requirements.filter((r) => r.mainsectionid === selectedSection)
      );
    }
  };

  const searchSection = (
    event: AnchorInputCustomEvent<AnchorInputEvent>
  ): void => {
    if (sectionData) {
      const filter = event.detail.value as string;
      if (filter === '') {
        setVisSections(sectionData);
      } else {
        const newObj: ISection = {};
        Object.keys(sectionData).forEach((val) => {
          if (val.includes(filter) || sectionData[val].includes(filter)) {
            newObj[val] = sectionData[val];
          }
        });
        setVisSections(newObj);
      }
    }
  };

  useEffect(() => {
    if (selectedProduct && selectedProduct !== 'notselected' && offer) {
      if (selectedProduct === 'summary') {
        fetchSummary();
      } else {
        fetchSave();
      }
    }
  }, [selectedProduct, offer]);

  useEffect(() => {
    if (selectedSection) {
      if (activeTab === 'Bulletins') {
        setVisRequirements(
          requirements
            ?.filter((req) => req.name === 'Bulletins')[0]
            .has_requirements.filter((r) => r.mainsectionid === selectedSection)
        );
      } else {
        setVisRequirements(
          requirements
            ?.filter((req) => req.name === 'Scheduled Maintenance')[0]
            .has_requirements.filter((r) => r.mainsectionid === selectedSection)
        );
      }
    }
  }, [selectedSection]);

  useEffect(() => {
    if (accessToken && offer) {
      fetchData(offer);
    }
  }, [offer, accessToken]);

  useEffect(() => {
    const groupedParts: IStringIndex = {};
    basket?.forEach((part: IBasket) => {
      if (!Object.keys(groupedParts).includes(part.basketType)) {
        groupedParts[part.basketType] = {};
      }

      if (
        Object.keys(groupedParts).includes(part.basketType) &&
        !Object.keys(groupedParts[part.basketType]).includes(part.equipment)
      ) {
        groupedParts[part.basketType][part.equipment] = {};
      }

      if (
        Object.keys(groupedParts).includes(part.basketType) &&
        Object.keys(groupedParts[part.basketType]).includes(part.equipment) &&
        !Object.keys(groupedParts[part.basketType][part.equipment]).includes(
          part.mainsectionname
        )
      ) {
        groupedParts[part.basketType][part.equipment][part.mainsectionname] =
          [];
      }

      groupedParts[part.basketType][part.equipment][part.mainsectionname].push({
        ...part,
      });
    });

    setGBasket(groupedParts);
    setBLoad(false);
  }, [basket]);

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct !== 'notselected' &&
      isServicesSelected
    ) {
      setSection('');
      fetchSections();
      fetchServiceRequirements();
    }
  }, [selectedProduct, isServicesSelected]);

  useEffect(() => {
    if (selectedRow !== '' && visibleRequirements) {
      const req = visibleRequirements.find((r) => r.id === selectedRow);
      const cylinders = data?.configuration_object.find(
        (e) => e.product_number === selectedProduct
      )?.cylinder_count;
      let count = 1;
      if (req && req.factor && cylinders) {
        switch (req.factor) {
          case 'CYLINDER':
            count = parseInt(cylinders, 10);
            break;
          case 'ENGINE':
            count = 1;
            break;
          case 'BANK CYLINDER':
            count = parseInt(cylinders, 10) / 2;
            break;
          default:
            count = 1;
        }
      }
      setCylCount(count);
    }
  }, [selectedRow]);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent): void => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'Do you want to save your changes before leaving?';
    };

    if (isUnsaved !== 'unchanged') {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    return undefined;
  }, [isUnsaved]);

  return (
    <Wrapper>
      <AnchorSpacing name="compact">
        <div className="ds-container--24">
          <div className="ds-row">
            <div className="ds-col-6">
              <AnchorInput
                style={searchStyles}
                type="text"
                value={searchValue}
                placeholder="Type to search..."
                name="other"
                leadingIcon="search"
                onKeyDown={handleKeyDown}
              />
              <br />
              {isLoading && (
                <div>
                  <Loader />
                </div>
              )}
              {data && !isLoading && (
                <div>
                  <AnchorCard style={{ width: '100%' }}>
                    <div>
                      <Disclaimer style={{ fontWeight: 'bold' }}>
                        {data.so_name}
                      </Disclaimer>
                      <hr style={{ marginBottom: '-2px', marginTop: '-2px' }} />
                      <Disclaimer>
                        <AnchorIcon size="12" name="vessel-side-filled" />
                        {data.installation_id} - {data.installation_name}
                      </Disclaimer>
                      {data.configuration_object.map((equipment) => (
                        <div key={equipment.equipment_id}>
                          <AnchorIcon size="12" name="engine" />
                          <Disclaimer>
                            {equipment.product_number} -{' '}
                            {equipment.for_equipment[0].type_extension} -{' '}
                            {equipment.nickname}
                          </Disclaimer>
                        </div>
                      ))}
                    </div>
                  </AnchorCard>
                </div>
              )}
            </div>
            <div className="ds-col-3">
              <Disclaimer>Applied Configuration Logic</Disclaimer>
              <br />
              <AnchorIcon size="12" name="maintenance" /> Custom Scope
              <AnchorIcon size="12" name="down-triangle" />
            </div>
            <div className="ds-col-3">
              <Disclaimer>Custom Scope</Disclaimer>
              <br />
              <Disclaimer>12.000</Disclaimer>
              <br />
              <Disclaimer>24.000</Disclaimer>
              <br />
              <Disclaimer>48.000</Disclaimer>
            </div>
            <div className="ds-col-3">
              <AnchorToggle
                style={toggleStyle}
                checked={false}
                label="Include Upgrades/Bulletins"
                name="test 1"
              />
              <br />
              <AnchorToggle
                style={toggleStyle}
                checked
                label="Use Similar Offers"
                name="test 2"
              />
              <br />
              <AnchorToggle
                style={toggleStyle}
                checked
                label="Use As-maintained"
                name="test 2"
              />
              <br />
              <br />
              <Info>Engine Room Condition 2</Info>
            </div>
            <div className="ds-col-3">
              <div className="ds-row">
                <div>
                  <AnchorIcon size="24" name="user-multiple-filled" />
                </div>
                <div>
                  <Disclaimer>Wärtsilä Network</Disclaimer>
                  <br />
                  <Disclaimer>NL Team</Disclaimer>
                  <AnchorIcon size="12" name="down-triangle" />
                </div>
              </div>
              <br />
              <div className="ds-row">
                <div>
                  <AnchorIcon size="24" name="pivot-point" />
                </div>
                <div>
                  <Disclaimer>Work Location</Disclaimer>
                  <br />
                  <Disclaimer>ES</Disclaimer>
                  <AnchorIcon size="12" name="down-triangle" />
                </div>
              </div>
            </div>
            <div className="ds-col-3">
              <AnchorButton
                leadingIcon="backward"
                variation="ghost"
                onClick={handleBackNav}>
                Back to timeline
              </AnchorButton>
              {!isLoading && data && (
                <>
                  <br />
                  <AnchorButton leadingIcon="export">Export Lists</AnchorButton>
                  <br />
                  {selectedProduct !== 'summary' &&
                    selectedProduct !== 'notselected' &&
                    isUnsaved && (
                      <AnchorButton
                        variation={
                          isUnsaved !== 'unchanged' ? 'primary' : 'standard'
                        }
                        leadingIcon="save"
                        onClick={save}>
                        Save
                      </AnchorButton>
                    )}
                </>
              )}
            </div>
          </div>
          {isLoading && (
            <div style={{ marginTop: '25px' }} className="ds-container">
              <div className="ds-row">
                <div
                  style={{
                    backgroundColor: '#fff',
                    justifyContent: 'space-evenly',
                    borderRadius: '15px',
                    padding: '10px',
                  }}
                  className="ds-col-5">
                  <Loader key="loadservice" />
                </div>
                <div style={{ margin: '0 auto' }} className="ds-col-s-1" />
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    padding: '10px',
                  }}
                  className="ds-col-6">
                  <Loader key="loadreq" />
                </div>
              </div>
            </div>
          )}
          {offer && !isLoading && !data && (
            <div
              style={{
                height: '100vh',
                lineHeight: '70vh',
                textAlign: 'center',
                justifyContent: 'center',
                fontWeight: 'lighter',
                fontSize: '25px',
              }}>
              Oops! SO ID not found.
            </div>
          )}
          {!isLoading && data && (
            <div
              style={{ marginTop: '25px', minHeight: '80vh' }}
              className="ds-container">
              <div className="ds-row">
                {(selectedProduct === 'notselected' ||
                  selectedProduct === 'summary') && (
                  <div
                    style={{
                      backgroundColor: '#fff',
                      justifyContent: 'space-evenly',
                      borderRadius: '15px',
                      padding: '10px',
                    }}
                    className="ds-col-5">
                    Please choose one equipment, to add new items to basket.
                  </div>
                )}
                {selectedProduct !== 'notselected' &&
                  selectedProduct !== 'summary' && (
                    <div
                      style={{
                        backgroundColor: '#fff',
                        justifyContent: 'space-evenly',
                        borderRadius: '15px',
                        padding: '10px',
                      }}
                      className="ds-col-5">
                      <AnchorInput
                        style={searchStyles}
                        type="text"
                        name="other"
                        leadingIcon="search"
                        onInputChange={(e) => searchSection(e)}
                      />
                      <div style={{ float: 'right', margin: '5px' }}>
                        <SelectionButton
                          $selected={isServicesSelected}
                          onClick={handleServiceSelect}>
                          Services
                        </SelectionButton>
                        <SelectionButton $selected={false}>
                          Spare Parts
                        </SelectionButton>
                      </div>
                      {isSectionsLoading && <Loader />}
                      {isServicesSelected && (
                        <>
                          <SectionWrap>
                            {!isSectionsLoading &&
                              isServicesSelected &&
                              visibleSections &&
                              Object.keys(visibleSections)
                                .sort()
                                .map((s) => (
                                  <SectionCard
                                    key={visibleSections[s]}
                                    $selected={selectedSection === s}
                                    $reqLoading={isRequirementsLoading}
                                    title={visibleSections[s]}
                                    onClick={() =>
                                      s === selectedSection
                                        ? setSection('')
                                        : setSection(s)
                                    }>
                                    {s}{' '}
                                    {visibleSections[s].length > 20
                                      ? `${visibleSections[s].substring(
                                          0,
                                          20
                                        )}...`
                                      : visibleSections[s]}
                                  </SectionCard>
                                ))}
                          </SectionWrap>
                          <hr />
                          {isRequirementsLoading && <Loader />}
                          {!isRequirementsLoading && selectedSection !== '' && (
                            <>
                              <AnchorTabs
                                onTabsSelect={(event) => switchTab({ event })}
                                initialActiveTab={requirements?.findIndex(
                                  (r) => r.name === activeTab
                                )}
                                style={{
                                  '--ds-tab-panel-margin-y': '-3px',
                                  '--ds-tab-panel-margin-x': '-2px',
                                }}>
                                {requirements?.map((req) => (
                                  <AnchorTabPanel
                                    key={req.id + req.name}
                                    id={req.name}
                                    label={req.name}>
                                    <AnchorSpacing name="compact">
                                      <AnchorList
                                        style={{
                                          width: '99%',
                                        }}>
                                        <AnchorSpacing name="compact">
                                          <AnchorListItem
                                            key="header"
                                            className="ds-row"
                                            disabled
                                            style={{ color: 'black' }}>
                                            <div
                                              className="ds-col-2"
                                              style={{
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                              }}>
                                              Interval
                                            </div>
                                            <div
                                              className="ds-col-6"
                                              style={{
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                              }}>
                                              Activity
                                            </div>
                                            <div
                                              className="ds-col-2"
                                              style={{
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                              }}>
                                              WS Code
                                            </div>
                                            <div
                                              className="ds-col-2"
                                              style={{
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                              }}>
                                              Last Done
                                            </div>
                                          </AnchorListItem>
                                        </AnchorSpacing>
                                        <div
                                          style={{
                                            height: '100%',
                                            maxHeight: '30vh',
                                            overflowY: 'auto',
                                            overflowX: 'hidden',
                                          }}>
                                          <AnchorSpacing name="compact">
                                            {visibleRequirements?.map(
                                              (vreq) => (
                                                <AnchorListItem
                                                  key={req.id}
                                                  selected={
                                                    selectedRow === vreq.id ||
                                                    basket.filter(
                                                      (b) =>
                                                        b.id === vreq.id &&
                                                        b.equipment ===
                                                          selectedProduct
                                                    ).length > 0
                                                  }
                                                  onClick={() =>
                                                    setRow(vreq.id)
                                                  }
                                                  className="ds-row">
                                                  <div
                                                    className="ds-col-2"
                                                    style={{
                                                      fontSize: '12px',
                                                    }}>
                                                    {vreq.frequency}
                                                  </div>
                                                  <div
                                                    className="ds-col-6"
                                                    style={{
                                                      fontSize: '12px',
                                                    }}>
                                                    {vreq.name}
                                                  </div>
                                                  <div
                                                    className="ds-col-2"
                                                    style={{
                                                      fontSize: '12px',
                                                    }}>
                                                    {vreq.wscode}
                                                  </div>
                                                  <div
                                                    className="ds-col-2"
                                                    style={{ fontSize: '12px' }}
                                                  />
                                                </AnchorListItem>
                                              )
                                            )}
                                          </AnchorSpacing>
                                        </div>
                                      </AnchorList>
                                    </AnchorSpacing>
                                  </AnchorTabPanel>
                                ))}
                              </AnchorTabs>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                <SelectButtons className="ds-col-1">
                  {selectedRow && (
                    <div style={{ marginBottom: '5px' }}>
                      <AnchorTooltip content="Activities">
                        <span
                          style={{
                            border: '1px solid black',
                            padding: '5px 8px 5px 8px',
                          }}>
                          {cylinderCount}
                        </span>
                      </AnchorTooltip>
                    </div>
                  )}
                  <div>
                    <AnchorButton variation="ghost" onClick={addToBasket}>
                      <AnchorIcon
                        size="28"
                        name="chevron-right-double"
                        slot="icon-only"
                      />
                    </AnchorButton>
                  </div>
                </SelectButtons>
                {data && basket && groupedBasket && (
                  <Basket
                    basket={basket}
                    data={data}
                    groupedBasket={groupedBasket}
                    selectedProduct={selectedProduct}
                    isBasketLoading={isBasketLoading}
                    isUnsaved={isUnsaved}
                    setProduct={setProduct}
                    deleteItem={deleteItem}
                    setBasket={setBasket}
                    setUnsaved={setUnsaved}
                    save={save}
                  />
                )}
              </div>
            </div>
          )}
          {!isLoading && data && (
            <div>
              <AnchorExpansionPanel header="Basket Data">
                <pre
                  style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    maxWidth: '1000px',
                    height: '400px',
                    border: '0.5px solid lightgray',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {JSON.stringify(basket, undefined, 2)}
                </pre>
              </AnchorExpansionPanel>
              <AnchorExpansionPanel header="Service Requirements Data">
                <pre
                  style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    maxWidth: '1000px',
                    height: '400px',
                    border: '0.5px solid lightgray',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {JSON.stringify(visibleRequirements, undefined, 2)}
                </pre>
              </AnchorExpansionPanel>
              <AnchorExpansionPanel header="Data">
                <pre>{JSON.stringify(data, undefined, 2)}</pre>
              </AnchorExpansionPanel>
            </div>
          )}
        </div>
        <Outlet />
      </AnchorSpacing>
    </Wrapper>
  );
};
