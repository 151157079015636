import React, { FC, memo, useEffect, useState } from 'react';
import {
  AnchorButton,
  AnchorIcon,
  AnchorInput,
  AnchorSpacing,
} from '@anchor-ds/react';
import update from 'immutability-helper';
import { ButtonWrapper, useMounted } from './util';
import { IBasket, ISparePart } from './Configurator.types';

export interface SparePartRowProps {
  wcIndex: number;
  spIndex: number;
  sp: ISparePart;
  bid: string;
  defaultBasket: IBasket[];
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  isEditable: boolean;
}
export const SparePartRow: FC<SparePartRowProps> = memo(
  ({ sp, bid, spIndex, wcIndex, defaultBasket, setBasket, setUnsaved, isEditable }) => {
    const [quantity, setQuantity] = useState<number>(parseInt(sp.qty, 10));
    const mounted = useMounted();

    useEffect(() => {
      if (mounted) {
        const bindex = defaultBasket.findIndex((bas) => bas.id === bid);
        const newItems: IBasket[] = update(defaultBasket, {
          [bindex]: {
            has_workcard: {
              [wcIndex]: {
                uses_sparepart: {
                  [spIndex]: { qty: { $set: quantity.toString() } },
                },
              },
            },
          },
        });
        if (bindex !== -1) {
          setBasket(newItems);
          setUnsaved("true");
        }
      }
    }, [quantity]);

    return (
      <tr key={sp.id}>
        <td>{sp.spnum}</td>
        <td>{sp.materialnumber}</td>
        <td>{sp.description}</td>
        <td>
          <AnchorSpacing name="compact">
            <ButtonWrapper>
              <AnchorButton
                onClick={() =>
                  quantity !== 0
                    ? setQuantity(quantity - 1)
                    : setQuantity(quantity)
                }
                margin="none"
                style={{display: !isEditable ? "none" : "block"}}
              >
                <AnchorIcon
                  color="#ffffff"
                  slot="icon-only"
                  name="minus"
                  size="16"
                />
              </AnchorButton>
              <AnchorInput
                style={{
                  '--ds-input-width': '60px',
                  '--ds-input-border-style': 'none',
                  '--ds-input-font-size': '12px',
                }}
                value={quantity}
                type="number"
                inputmode="numeric"
                name="qty"
                margin="none"
                maxlength={3}
                min="0"
                max="3"
                disabled={!isEditable}
              />
              <AnchorButton
                onClick={() => setQuantity(quantity + 1)}
                margin="none"
                style={{display: !isEditable ? "none" : "block"}}
              >
                <AnchorIcon
                  color="#ffffff"
                  slot="icon-only"
                  name="plus"
                  size="16"
                />
              </AnchorButton>
            </ButtonWrapper>
          </AnchorSpacing>
        </td>
      </tr>
    );
  }
);
