import React, { FC, memo, useEffect, useState } from 'react';
import {
  AnchorButton,
  AnchorIcon,
  AnchorInput,
  AnchorSpacing,
} from '@anchor-ds/react';
import update from 'immutability-helper';
import { useDrag } from 'react-dnd';
import { BasketRowHeader, useMounted } from './util';
import { IBasket } from './Configurator.types';
import { WorkCardRow } from './WorkCardRow.component';

export interface CardProps {
  b: IBasket;
  deleteItem: (bid: string) => void;
  defaultBasket: IBasket[];
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  isEditable: boolean;
}
export const Card: FC<CardProps> = memo(
  ({ b, deleteItem, defaultBasket, setBasket, setUnsaved, isEditable }) => {
    const mounted = useMounted();
    const [multiplier, setMultiplier] = useState(b.cylinderCount);
    const [manHours, setManHours] = useState(
      b?.has_workcard[0]?.contains[0]?.time_h
        ? parseFloat(b?.has_workcard[0].contains[0].time_h) * b.cylinderCount
        : 0
    );
    const [{ opacity }, drag, dragPreview] = useDrag(
      () => ({
        type: b.basketType,
        item: b,
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [b]
    );

    useEffect(() => {
      if (
        mounted &&
        b?.has_workcard[0]?.contains[0]?.time_h &&
        multiplier &&
        multiplier !== 0
      ) {
        const newValue =
          multiplier * parseFloat(b.has_workcard[0].contains[0].time_h);
        const index = defaultBasket.findIndex((bas) => bas.id === b.id);
        const newItems: IBasket[] = update(defaultBasket, {
          [index]: { cylinderCount: { $set: multiplier } },
        });
        setBasket(newItems);
        setManHours(newValue);
        setUnsaved('true');
      }
    }, [multiplier]);
    return (
      <div
        className="ds-container"
        style={{ transform: 'translate3d(0, 0, 0)', opacity }}
        ref={dragPreview}>
        <div ref={isEditable ? drag : null}>
          <BasketRowHeader className="ds-row">
            <div className="ds-col-1">
              <AnchorSpacing name="compact">
                <AnchorInput
                  style={{
                    '--ds-input-width': '45px',
                    float: 'right',
                  }}
                  value={multiplier}
                  unitLabel="x"
                  type="number"
                  name="password"
                  margin="none"
                  onInputChange={(event) =>
                    setMultiplier(event.detail.value as number)
                  }
                  disabled={!isEditable}
                />
              </AnchorSpacing>
            </div>
            <div
              className="ds-col-9"
              style={{ fontSize: '12px' }}
              title={b.name}>
              {b.wscode ? b.wscode : b.frequency}{' '}
              {b.name.length > 70 ? `${b.name.substring(0, 70)}...` : b.name}
            </div>
            <div className="ds-col-1">
              <AnchorSpacing name="compact">
                <AnchorInput
                  style={{
                    '--ds-input-width': '100px',
                    float: 'right',
                  }}
                  value={manHours}
                  unitLabel="Manhours"
                  type="number"
                  name="password"
                  margin="none"
                  onInputChange={(event) =>
                    setManHours(event.detail.value as number)
                  }
                  disabled
                />
              </AnchorSpacing>
            </div>
            <div
              className="ds-col-1"
              style={{ display: 'flex', justifyItems: 'right' }}>
              <AnchorButton
                margin="none"
                onClick={() => deleteItem(b.id)}
                disabled={!isEditable}>
                <AnchorIcon
                  color="#ffffff"
                  slot="icon-only"
                  name="delete"
                  size="16"
                />
              </AnchorButton>
            </div>
          </BasketRowHeader>
          {b?.has_workcard.map((w, index) => (
            <WorkCardRow
              index={index}
              workcard={w}
              basket={b}
              defaultBasket={defaultBasket}
              setBasket={setBasket}
              setUnsaved={setUnsaved}
              isEditable={isEditable}
            />
          ))}
        </div>
      </div>
    );
  }
);
