import React, { FC, memo } from 'react';
import { AnchorExpansionPanel } from '@anchor-ds/react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './util';
import { IBasket } from './Configurator.types';
import { Card } from './Card.component';

export interface OnsiteCardProps {
  index: number;
  basket: IBasket[];
  deleteItem: (bid: string) => void;
  defaultBasket: IBasket[];
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  isEditable: boolean;
}
export const OnsiteCard: FC<OnsiteCardProps> = memo(
  ({ index, basket, deleteItem, defaultBasket, setBasket, setUnsaved, isEditable }) => {
    const [{ opacity }, drag, dragPreview] = useDrag(
      () => ({
        type: ItemTypes.ONSITE,
        item: basket,
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [basket]
    );
    return (
      <>
        {index !== 0 ? <hr /> : null}
        <div style={{ transform: 'translate3d(0, 0, 0)' }} ref={dragPreview}>
          <div ref={isEditable ? drag : null}>
            <AnchorExpansionPanel
              header={`${basket[0].mainsectionid} ${basket[0].mainsectionname}`}
              style={{ width: '100%', opacity }}>
              {basket
                .filter((e) => e.basketType === 'Onsite')
                .map((b) => (
                  <Card
                    b={b}
                    deleteItem={deleteItem}
                    defaultBasket={defaultBasket}
                    setBasket={setBasket}
                    setUnsaved={setUnsaved}
                    isEditable={isEditable}
                  />
                ))}
            </AnchorExpansionPanel>
          </div>
        </div>
      </>
    );
  }
);
