import './styles.index.scss';
import '@anchor-ds/fonts/dist/anchor-fonts.css';
import '@anchor-ds/core/dist/anchor-ds/anchor-ds.css';
import './theme-custom.scss';

import axios from 'axios';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  AccountInfo,
  Configuration,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { AnchorSpinner } from '@anchor-ds/react';
import moment from 'moment/moment';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import App from './pages/Index/App';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

const AuthError = ({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element => (
  <AuthStatusScreen>
    <div className="auth__error-title">An Error Occurred</div>

    {error?.errorMessage ? (
      <div className="auth__error-message">{error.errorMessage}</div>
    ) : null}
  </AuthStatusScreen>
);

const AuthLoading = (): JSX.Element => (
  <AuthStatusScreen>
    <AnchorSpinner size="48" />
  </AuthStatusScreen>
);

export type IAuthContext = {
  accessToken: string;
  account: AccountInfo;
  config: Record<string, unknown>;
};

export const AuthContext = React.createContext<IAuthContext>({
  accessToken: '',
  config: {},
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  },
});

const loading = (): JSX.Element => <AnchorSpinner size="24" />;

axios
  .get('/config.json')
  .then((res) => {
    const config = res.data;

    const authConfiguration: Configuration = {
      auth: {
        redirectUri: '/',
        clientId: config.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    const authInstance = new PublicClientApplication(authConfiguration);

    const AuthProvider = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      const auth = useMsal();
      const [account] = auth.instance.getAllAccounts();
      const [rumInitiated, setRum] = React.useState(false);
      const [accessToken, setAccessToken] = React.useState('');

      React.useEffect(() => {
        if (!rumInitiated) {
          const DATADOG_SITE = 'datadoghq.eu';
          const DATADOG_SERVICE = 'react-template';
          const DATADOG_PROXY_HOST = 'datadog-proxy.app.wartsila.com';
          const DATADOG_APP_ID = 'app-id';
          const DATADOG_CLIENT_TOKEN = 'client-token';

          datadogRum.init({
            sampleRate: 100,
            site: DATADOG_SITE,
            trackInteractions: true,
            service: DATADOG_SERVICE,
            applicationId: DATADOG_APP_ID,
            proxyHost: DATADOG_PROXY_HOST,
            clientToken: DATADOG_CLIENT_TOKEN,
            env: config.REACT_APP_ENVIRONMENT,
            version: config.REACT_APP_VERSION,
            allowedTracingOrigins: [config.REACT_APP_API_BASE_URL],
          });

          datadogRum.setUser({
            name: account.name,
            email: account.username,
          });

          setRum(true);
        }
      }, [rumInitiated, account]);

      React.useEffect(() => {
        if (!accessToken) {
          auth.instance
            .acquireTokenSilent({
              account,
              scopes: [`${config.REACT_APP_API_CLIENT_ID}/.default`],
            })
            .then((accountInformation) =>
              setAccessToken(accountInformation.accessToken)
            );
        }
      }, [accessToken]);

      return (
        <AuthContext.Provider value={{ accessToken, account, config }}>
          {children}
        </AuthContext.Provider>
      );
    };

    moment.locale('fi');

    moment.updateLocale('fi', {
      week: {
        dow: 1,
        doy: 4,
      },
    });

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <MsalProvider instance={authInstance}>
          <MsalAuthenticationTemplate
            errorComponent={AuthError}
            loadingComponent={AuthLoading}
            interactionType={InteractionType.Redirect}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <React.Suspense fallback={loading()}>
                  <DndProvider backend={HTML5Backend}>
                    <App />
                  </DndProvider>
                </React.Suspense>
              </AuthProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </React.StrictMode>
    );

    serviceWorker.unregister();
  })
  .catch();
