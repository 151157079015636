import React, { FC, memo, useEffect, useState } from 'react';
import {
  AnchorCheckbox,
  AnchorExpansionPanel,
  AnchorIcon,
  AnchorMenuItem,
  AnchorSelect,
  AnchorSpacing,
  AnchorTable,
  AnchorTooltip,
} from '@anchor-ds/react';
import update from 'immutability-helper';
import { IBasket, IWorkCard } from './Configurator.types';
import { SparePartRow } from './SparePartRow.component';
import { useMounted } from './util';

export interface WorkCardRowProps {
  index: number;
  workcard: IWorkCard;
  basket: IBasket;
  defaultBasket: IBasket[];
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  isEditable: boolean;
}
export const WorkCardRow: FC<WorkCardRowProps> = memo(
  ({ index, workcard, basket, defaultBasket, setBasket, setUnsaved, isEditable }) => {
    const mounted = useMounted();
    const [responsible, setResponsible] = useState(workcard.responsible);
    const [isSelected, setSelected] = useState(workcard.isSelected);
    useEffect(() => {
      if (mounted) {
        const bindex = defaultBasket.findIndex((bas) => bas.id === basket.id);
        const newItems: IBasket[] = update(defaultBasket, {
          [bindex]: {
            has_workcard: { [index]: { responsible: { $set: responsible } } },
          },
        });
        if (bindex) {
          setBasket(newItems);
          setUnsaved('true');
        }
      }
    }, [responsible]);

    useEffect(() => {
      if (mounted) {
        const bindex = defaultBasket.findIndex((bas) => bas.id === basket.id);
        const newItems: IBasket[] = update(defaultBasket, {
          [bindex]: {
            has_workcard: { [index]: { isSelected: { $set: isSelected } } },
          },
        });
        if (bindex) {
          setBasket(newItems);
          setUnsaved('true');
        }
      }
    }, [isSelected]);

    return (
      <div
        style={{
          border: '1px solid black',
          backgroundColor: 'white',
          position: 'relative',
          paddingTop: '5px',
        }}
        className="ds-row">
        <div style={{ width: '2%' }}>
          <AnchorCheckbox
            checked={isSelected}
            onCheckboxChange={(event) => setSelected(event.detail.checked)}
            disabled={!isEditable}
          />
        </div>
        <div style={{ width: '96%' }}>
          <AnchorExpansionPanel
            key={workcard.id}
            header={
              basket.name.length > 80
                ? `${basket.name.substring(0, 80)}...`
                : basket.name
            }
            title={basket.name}>
            <AnchorTable
              style={{
                width: '75vh',
                marginTop: '15px',
              }}>
              <table>
                <thead>
                  <tr>
                    <th>Spare Part Number</th>
                    <th>Material Number</th>
                    <th>Description</th>
                    <th style={{ width: '75px' }}>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {workcard.uses_sparepart.map((s, i) => (
                    <SparePartRow
                      key={`${i + 1}row`}
                      wcIndex={index}
                      spIndex={i}
                      bid={basket.id}
                      sp={s}
                      defaultBasket={defaultBasket}
                      setBasket={setBasket}
                      setUnsaved={setUnsaved}
                      isEditable={isEditable}
                    />
                  ))}
                </tbody>
              </table>
            </AnchorTable>
          </AnchorExpansionPanel>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '16px',
          }}>
          <AnchorSpacing name="compact">
            <AnchorSelect
              placement="top-start"
              placeholder="Select"
              value={responsible ? 'C' : 'W'}
              onSelectChange={(event) =>
                setResponsible((event.detail.value as string) === 'C')
              }
              disabled={!isEditable}
            >
              <AnchorMenuItem value="C" label="Customer" />
              <AnchorMenuItem value="W" label="Wärtsilä" />
            </AnchorSelect>
          </AnchorSpacing>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '112px',
            display: workcard.description ? 'block' : 'none',
          }}>
          <AnchorSpacing name="compact">
            <AnchorTooltip content={workcard.description}>
              <AnchorIcon name="information" size="24" />
            </AnchorTooltip>
          </AnchorSpacing>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '142px',
            display: workcard.requires_skill[0]?.name ? 'block' : 'none',
          }}>
          <AnchorSpacing name="compact">
            <AnchorTooltip content={workcard.requires_skill[0]?.name}>
              <AnchorIcon name="engineering" size="24" />
            </AnchorTooltip>
          </AnchorSpacing>
        </div>
      </div>
    );
  }
);
