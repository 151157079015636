import update from 'immutability-helper';
import type { FC } from 'react';
import React, { memo, useCallback, useState } from 'react';
import {
  IStringIndex,
  ItemTypes,
  Loader,
  ProductButton,
  SelectionButton,
} from './util';
import { IBasket, IOffer } from './Configurator.types';
import { Onsite } from './Onsite.component';
import { Workshop } from './Workshop.component';
import { Optional } from './Optional.component';

export interface BasketProps {
  data: IOffer;
  basket: IBasket[];
  groupedBasket: IStringIndex;
  selectedProduct: string;
  isBasketLoading: boolean;
  isUnsaved: string;
  setProduct: (value: string) => void;
  deleteItem: (bid: string) => void;
  setBasket: (baskets: IBasket[]) => void;
  setUnsaved: (value: string) => void;
  save: () => Promise<void>;
}

export interface DustbinState {
  accepts: string[];
  type: 'Workshop' | 'Onsite' | 'Optional';
  lastDroppedItem: IBasket[] | null;
}

export const Basket: FC<BasketProps> = memo(
  ({
    data,
    basket,
    groupedBasket,
    selectedProduct,
    setProduct,
    deleteItem,
    setBasket,
    isUnsaved,
    isBasketLoading,
    setUnsaved,
    save,
  }) => {
    const [dustbins, setDustbins] = useState<DustbinState[]>([
      {
        accepts: [ItemTypes.WORKSHOP, ItemTypes.OPTIONAL],
        type: 'Onsite',
        lastDroppedItem: null,
      },
      {
        accepts: [ItemTypes.ONSITE, ItemTypes.OPTIONAL],
        type: 'Workshop',
        lastDroppedItem: null,
      },
      {
        accepts: [ItemTypes.ONSITE, ItemTypes.WORKSHOP],
        type: 'Optional',
        lastDroppedItem: null,
      },
    ]);

    const handleDrop = useCallback(
      (index: number, item: IBasket[] | IBasket) => {
        let newType: 'Workshop' | 'Onsite' | 'Optional';
        if (index === 0) {
          newType = 'Onsite';
        } else if (index === 1) {
          newType = 'Workshop';
        } else {
          newType = 'Optional';
        }

        if(Array.isArray(item)) {
            const ids = item.map((a) => a.id);
            const newItems: IBasket[] = item.map((b) =>
                update(b, { basketType: { $set: newType } })
            );
            const newBasket: IBasket[] = basket.filter((b) => !ids.includes(b.id));
            const veryNewBasket: IBasket[] = [...newBasket, ...newItems];
            setBasket(veryNewBasket);
            setDustbins(
                update(dustbins, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                })
            );
        } else {
            const ids = item.id;
            const basget: IBasket[] = [];
            const newItem: IBasket = update(item, { basketType: { $set: newType } });
            const newBasket: IBasket[] = basket.filter((b) => b.id !== ids);
            basget.push(newItem);
            const veryNewBasket: IBasket[] = [...newBasket, ...basget];
            setBasket(veryNewBasket);
        }

        setUnsaved('true');
      },
      [dustbins, basket]
    );

    const changeProduct = async (equipment: string): Promise<void> => {
      if (!isBasketLoading) {
        if (isUnsaved !== 'unchanged') {
          if (
            window.confirm(
              'You have unsaved changes, do you want to save before changing Equipment.'
            )
          ) {
            await save().then(() => {
              setBasket([]);
              setProduct(selectedProduct === equipment ? '' : equipment);
            });
          }
        } else {
          setBasket([]);
          setProduct(selectedProduct === equipment ? '' : equipment);
        }
      }
    };

    return (
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '15px',
          padding: '10px',
        }}
        className="ds-col-6">
        <div
          style={{
            margin: '5px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <SelectionButton $selected={false}>Component View</SelectionButton>
          <SelectionButton $selected={false}>
            Material Deviations
          </SelectionButton>
          <SelectionButton $selected={false}>Spare Part List</SelectionButton>
          <SelectionButton $selected={false}>Scope View</SelectionButton>
          <SelectionButton $selected={false}>Commercial View</SelectionButton>
        </div>
        <div style={{ marginTop: '10px' }}>
          {data?.configuration_object.map((equipment) => (
            <>
              <ProductButton
                key={equipment.id}
                $selected={selectedProduct === equipment.product_number}
                $warning={selectedProduct === 'notselected'}
                onClick={() => changeProduct(equipment.product_number)}>
                {equipment.product_number}
              </ProductButton>
              {selectedProduct === 'notselected' && (
                <span style={{ fontSize: '12px' }}>Please select product</span>
              )}
            </>
          ))}
          <ProductButton
            key="summary"
            $selected={selectedProduct === 'summary'}
            $warning={selectedProduct === 'notselected'}
            onClick={() => changeProduct('summary')}>
            Summary
          </ProductButton>
        </div>
        <div
          style={{
            height: 'auto',
            maxHeight: '60vh',
            overflowX: 'hidden',
          }}>
          {selectedProduct === 'notselected' ||
            (selectedProduct === '' && (
              <div
                style={{
                  height: '60vh',
                  lineHeight: '60vh',
                  textAlign: 'center',
                }}>
                Choose equipment
              </div>
            ))}
          {isBasketLoading && (
            <div
              style={{
                height: '60vh',
                lineHeight: '60vh',
                textAlign: 'center',
              }}>
              <Loader />
            </div>
          )}
          {!isBasketLoading && basket && selectedProduct && (
            <div
              style={{
                height: '60vh',
              }}>
              <Onsite
                key={`${0}onsite`}
                groupedBasket={groupedBasket}
                deleteItem={deleteItem}
                defbasket={basket}
                equipment={selectedProduct}
                setBasket={setBasket}
                dustbin={dustbins[0]}
                onDrop={(item) => handleDrop(0, item)}
                setUnsaved={setUnsaved}
              />
              <Workshop
                key={`${0}Workshop`}
                groupedBasket={groupedBasket}
                deleteItem={deleteItem}
                defbasket={basket}
                setBasket={setBasket}
                equipment={selectedProduct}
                dustbin={dustbins[1]}
                onDrop={(item) => handleDrop(1, item)}
                setUnsaved={setUnsaved}
              />
              <Optional
                key={`${0}Optional`}
                groupedBasket={groupedBasket}
                deleteItem={deleteItem}
                defbasket={basket}
                setBasket={setBasket}
                equipment={selectedProduct}
                dustbin={dustbins[2]}
                onDrop={(item) => handleDrop(2, item)}
                setUnsaved={setUnsaved}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
