import {
    AnchorButton,
    AnchorButtonGroup,
    AnchorDivider,
    AnchorInput,
    AnchorSpacing,
    AnchorToolbar
} from "@anchor-ds/react";
import React, {useEffect} from "react";
import {generatePath, useNavigate, useParams, Outlet} from "react-router-dom";
import {useAuth} from "../../features/auth/auth.hooks";

interface ToolbarProps {
    name: string;
    isLoading: boolean;
    fetchData(id: string): Promise<void>;
}

export const ToolbarComponent = ({name, isLoading, fetchData}: ToolbarProps): JSX.Element => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [instId, setId] = React.useState(id);
    const { accessToken } = useAuth();
    const handleKeyDown: React.KeyboardEventHandler<HTMLAnchorInputElement> = (
        event
    ) => {
        if (event.key === 'Enter') {
            const idInsty = (event.target as HTMLInputElement).value;
            if (idInsty) {
                setId(idInsty);
                navigate(generatePath('/:id', { id: idInsty }));
            }
        }
    };

    const handleConfiguratorNav: React.MouseEventHandler = () => {
        if(instId) {
            navigate(generatePath('/:id/configurator', { id: instId }));
        }
    };


    useEffect(() => {
        if (accessToken && id) {
            fetchData(id);
        }
    }, [instId, accessToken]);

    return (<><AnchorSpacing name="compact">
        <AnchorToolbar>
            <h3 className="ds-text-heading-lvl-2 ds-text-element ds-m-0">
                {isLoading ? "" : name}
            </h3>
            {!isLoading && name &&
            <AnchorButtonGroup slot="trailing" margin="none">
                <AnchorButton
                    variation="ghost"
                    trailingIcon="chevron-right">
                    Export to excel
                </AnchorButton>
                <AnchorDivider direction="vertical" />
                <AnchorButton
                    variation="ghost"
                    trailingIcon="chevron-right">
                    Customer History
                </AnchorButton>
                <AnchorDivider direction="vertical" />
                <AnchorButton
                    variation="ghost"
                    trailingIcon="chevron-right">
                    Asset Browser
                </AnchorButton>
                <AnchorDivider direction="vertical" />
                <AnchorButton
                    variation="ghost"
                    trailingIcon="chevron-right">
                    Bulletin Analyzes
                </AnchorButton>
                <AnchorDivider direction="vertical" />
                <AnchorButton
                    variation="ghost"
                    trailingIcon="chevron-right"
                    onClick={handleConfiguratorNav}>
                    Configurator
                </AnchorButton>
            </AnchorButtonGroup>}
            <AnchorButton variation="ghost" slot="trailing" margin="none">
                <AnchorInput
                    onKeyDown={handleKeyDown}
                    type="number"
                    name="search"
                    leadingIcon="search"
                    placeholder="Type to search..."
                    value={instId}
                    defaultValue={instId}
                />
            </AnchorButton>
        </AnchorToolbar>
        <Outlet /></AnchorSpacing>
    </>);
}
