import React, { useEffect, useState } from 'react';
import {
  AnchorButton,
  AnchorExpansionPanel,
  AnchorIcon,
  AnchorRangeSlider, AnchorSpacing,
  AnchorSpinbox,
  AnchorSpinner,
  AnchorToggle,
} from '@anchor-ds/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { IInstallation } from '../../pages/Index/App.types';
import { useAuth } from '../../features/auth/auth.hooks';
import { EquipmentComponent } from '../Equipment/Equipment.component';
import { IEventData } from '../Equipment/Equipment.types';

interface InstallationProps {
  data: IInstallation | undefined;
  isLoading: boolean;
  fetchData(id: string): Promise<void>;
}

export interface IFilters {
  showAll: boolean;
  showOrders: boolean;
  showSOrders: boolean;
  timelineDetails: boolean;
}

export interface IYearState {
  year: number;
  dataLoading: boolean;
}

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 40px;
  padding: 5px;
`;

const Wrap = styled.div`
  position: absolute;
  top: 10%;
  right: 1%;
  width: 205px;
  padding: 10px;
  background-color: #f4f5f6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 999;
`;

export const InstallationComponent = ({
  data,
  fetchData,
  isLoading,
}: InstallationProps): JSX.Element => {
  const [filters, setFilters] = useState<IFilters>({
    showAll: false,
    showOrders: true,
    showSOrders: true,
    timelineDetails: false,
  });
  const [selectedYear, setYear] = useState<IYearState>({
    year: moment().year(),
    dataLoading: false,
  });
  const [slider, setSlider] = useState<number>(3);
  const [month, setMonth] = useState(new Date().getMonth());
  const [isMonthModalVisible, setMonthModal] = useState(false);
  const { id } = useParams();
  const { accessToken, config } = useAuth();

  const fetchEvents = async (eqid: string, year: number): Promise<IEventData> =>
    axios
      .get<IEventData>(
        `${config.REACT_APP_API_BASE_URL}/tsb/events/${eqid}?date_start=${
          year - 3
        }-01-01&date_end=${year + 2}-12-31`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => response.data);

  useEffect(() => {
    if (accessToken && id) {
      fetchData(id);
    }
  }, [id, accessToken]);

  if (isLoading)
    return (
      <>
        <AnchorSpinner size="24" />
      </>
    );

  return (
    <>
      {data?.name !== '' && (
        <><AnchorSpacing name="compact">
          <div className="ds-container">
            <div className="ds-row">
              <div className="ds-col-2">
                <AnchorToggle
                  onToggleChange={() =>
                    setFilters({
                      showAll: !filters.showAll,
                      showOrders: filters.showOrders,
                      showSOrders: filters.showSOrders,
                      timelineDetails: filters.timelineDetails,
                    })
                  }
                  checked={filters.showAll}
                  label="Show all equipment"
                  name="test"
                />
              </div>
              <div className="ds-col-2">
                <AnchorToggle
                  onToggleChange={() =>
                    setFilters({
                      showAll: filters.showAll,
                      showOrders: !filters.showOrders,
                      showSOrders: filters.showSOrders,
                      timelineDetails: filters.timelineDetails,
                    })
                  }
                  checked={filters.showOrders}
                  label="Part orders"
                  name="test"
                />
              </div>
              <div className="ds-col-2">
                <AnchorToggle checked={false} label="RH View" name="test" />
              </div>
              <div className="ds-col-6">
            <AnchorSpinbox
              variation="ghost"
              onSpinboxChange={(e) =>
                setYear({ year: e.detail.value as number, dataLoading: true })
              }
              style={{ float: 'right' }}
              value={selectedYear.year}
              direction="horizontal"
              slot="trailing"
              onClick={() => setMonthModal(!isMonthModalVisible)}
            />
              </div>
            </div>
          </div>
          <div className="ds-container">
            <div className="ds-row">
              <div className="ds-col-2">
                <AnchorToggle
                  checked={filters.timelineDetails}
                  label="Timeline details"
                  name="test"
                  onToggleChange={() =>
                    setFilters({
                      showAll: filters.showAll,
                      showOrders: filters.showOrders,
                      showSOrders: filters.showSOrders,
                      timelineDetails: !filters.timelineDetails,
                    })
                  }
                />
              </div>
              <div className="ds-col-2">
                <AnchorToggle
                  onToggleChange={() =>
                    setFilters({
                      showAll: filters.showAll,
                      showOrders: filters.showOrders,
                      showSOrders: !filters.showSOrders,
                      timelineDetails: filters.timelineDetails,
                    })
                  }
                  checked={filters.showSOrders}
                  label="Service orders"
                  name="test"
                />
              </div>
              <div className="ds-col-2">
                <AnchorToggle
                  checked={false}
                  label="Show duration"
                  name="test"
                />
              </div>
            </div>
            <Wrap
              className="ds-container"
              style={{ display: !isMonthModalVisible ? 'none' : 'block' }}>
              <div className="ds-row">
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px', textAlign: 'center' }}
                  identifier="button1"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 1, 1).getMonth())
                  }>
                  JAN
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button2"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 2, 1).getMonth())
                  }>
                  FEB
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button3"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 3, 1).getMonth())
                  }>
                  MAR
                </AnchorButton>
              </div>
              <div className="ds-row" style={{ marginTop: '-10px' }}>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button1"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 4, 1).getMonth())
                  }>
                  APR
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button2"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 5, 1).getMonth())
                  }>
                  MAY
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button3"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 6, 1).getMonth())
                  }>
                  JUN
                </AnchorButton>
              </div>
              <div className="ds-row" style={{ marginTop: '-10px' }}>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button1"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 7, 1).getMonth())
                  }>
                  JUL
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button2"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 8, 1).getMonth())
                  }>
                  AUG
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button3"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 9, 1).getMonth())
                  }>
                  SEP
                </AnchorButton>
              </div>
              <div className="ds-row" style={{ marginTop: '-10px' }}>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button1"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 10, 1).getMonth())
                  }>
                  OCT
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button2"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 11, 1).getMonth())
                  }>
                  NOV
                </AnchorButton>
                <AnchorButton
                  variation="ghost"
                  style={{ width: '55px' }}
                  identifier="button3"
                  onClick={() =>
                    setMonth(new Date(selectedYear.year, 12, 1).getMonth())
                  }>
                  DEC
                </AnchorButton>
              </div>
              <div
                className="ds-row"
                style={{ marginTop: '-10px', justifyContent: 'center' }}>
                <AnchorButton
                  variation="ghost"
                  identifier="button3"
                  onClick={() => setMonth(new Date().getMonth())}>
                  MOVE TO TODAY
                </AnchorButton>
              </div>
            </Wrap>
          </div>
          <div className="ds-container">
            {data?.equipments.map((equip) => (
              <EquipmentComponent
                key={equip.id}
                equipment={equip}
                fetchEvents={fetchEvents}
                filters={filters}
                year={selectedYear.year}
                month={month}
                slider={slider}
              />
            ))}
          </div>
          <br />
          <br />
          <AnchorExpansionPanel header="Data">
            <pre>{JSON.stringify(data, undefined, 2)}</pre>
          </AnchorExpansionPanel>
          <Wrapper>
            <AnchorIcon
              name="minus"
              size="24"
              onClick={() =>
                slider !== 1 ? setSlider(slider - 1) : setSlider(1)
              }
            />
            <AnchorRangeSlider
              min={1}
              step={1}
              max={7}
              value={slider}
              onRangeSliderChange={(event) => setSlider(event.detail.value)}
              name="test"
            />
            <AnchorIcon
              name="plus"
              size="24"
              onClick={() =>
                slider !== 7 ? setSlider(slider + 1) : setSlider(7)
              }
            />
          </Wrapper></AnchorSpacing>
        </>
      )}
    </>
  );
};
