import React, { useState } from 'react';
import { AnchorIcon, AnchorTable } from '@anchor-ds/react';
import { ILine } from './Equipment.types';

interface LineProps {
  lines: ILine[];
}

interface ISort {
  code: boolean;
  mat: boolean;
  desc: boolean;
  qty: boolean;
}

const sortByString = (a: string, b: string): number => {
  if (a && b) {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  }
  // names must be equal
  return 0;
};

export const LineItemTable = ({ lines }: LineProps): JSX.Element => {
  const [sort, setSort] = useState<ISort>({
    code: false,
    mat: false,
    desc: false,
    qty: false,
  });

  const sortData = (data: ILine[]): ILine[] | undefined => {
    let result: ILine[];
    if (sort.code) {
      result = data.sort((a, b) =>
        sortByString(a.spare_part_nr, b.spare_part_nr)
      );
    } else {
      result = data.sort((a, b) =>
        sortByString(b.spare_part_nr, a.spare_part_nr)
      );
    }
    if (sort.mat) {
      result = data.sort((a, b) => sortByString(a.mat_id, b.mat_id));
    }
    if (sort.desc) {
      result = data.sort((a, b) =>
        sortByString(a.sd_doc_item_short_text, b.sd_doc_item_short_text)
      );
    }
    if (sort.qty) {
      result = data.sort(
        (a, b) =>
          parseInt(a.m_compl_deliv_qty_in_su, 10) -
          parseInt(b.m_compl_deliv_qty_in_su, 10)
      );
    }

    if (!sort.code && !sort.mat && !sort.qty && !sort.desc) {
      result = data;
    }

    return result;
  };

  return (
    <>
      <AnchorTable>
        <table>
          <thead>
            <tr>
              <th
                onClick={() =>
                  setSort({
                    code: !sort.code,
                    mat: false,
                    desc: false,
                    qty: false,
                  })
                }>
                <div className="ds-row">
                  Code
                  <AnchorIcon
                    name={!sort.code ? 'arrow-down-tiny' : 'arrow-up-tiny'}
                    size="12"
                  />
                </div>
              </th>
              <th
                onClick={() =>
                  setSort({
                    code: false,
                    mat: !sort.mat,
                    desc: false,
                    qty: false,
                  })
                }>
                <div className="ds-row">
                  Material
                  <AnchorIcon
                    name={!sort.mat ? 'arrow-down-tiny' : 'arrow-up-tiny'}
                    size="12"
                  />
                </div>
              </th>
              <th
                onClick={() =>
                  setSort({
                    code: false,
                    mat: false,
                    desc: !sort.desc,
                    qty: false,
                  })
                }>
                <div className="ds-row">
                  Description
                  <AnchorIcon
                    name={!sort.desc ? 'arrow-down-tiny' : 'arrow-up-tiny'}
                    size="12"
                  />
                </div>
              </th>
              <th
                onClick={() =>
                  setSort({
                    code: false,
                    mat: false,
                    desc: false,
                    qty: !sort.qty,
                  })
                }>
                <div className="ds-row">
                  Qty{' '}
                  <AnchorIcon
                    name={!sort.qty ? 'arrow-down-tiny' : 'arrow-up-tiny'}
                    size="12"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortData(lines)?.map((line) => (
              <tr>
                <td>{line.spare_part_nr}</td>
                <td>{line.mat_id}</td>
                <td>{line.sd_doc_item_short_text}</td>
                <td>
                  {Math.round(parseInt(line.m_compl_deliv_qty_in_su, 10))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AnchorTable>
    </>
  );
};
