import React, { FC, memo } from 'react';
import { useDrop } from 'react-dnd';
import { AnchorIcon } from '@anchor-ds/react';
import {BasketHeader, IStringIndex} from './util';
import { OptionalCard } from './OptionalCard.component';
import { DustbinState } from './Basket.component';
import { IBasket } from './Configurator.types';

export interface OptionalProps {
  groupedBasket: IStringIndex;
  dustbin: DustbinState;
  deleteItem: (bid: string) => void;
  onDrop: (item: IBasket[]) => void;
  defbasket: IBasket[];
  equipment: string;
  setBasket: (baskets: IBasket[]) => void;
    setUnsaved: (value: string) => void;
}
export const Optional: FC<OptionalProps> = memo(
  ({
    groupedBasket,
    dustbin,
    deleteItem,
    equipment,
    onDrop,
    defbasket,
    setBasket,
      setUnsaved,
  }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: dustbin.accepts,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = '#fff';
    if (isActive) {
      backgroundColor = '#8DC4FF';
    } else if (canDrop) {
      backgroundColor = '#EDEFF0';
    }
    return (
      <div
        ref={drop}
        style={{ backgroundColor, padding: '5px', marginTop: '5px' }}>
        <BasketHeader>
          <AnchorIcon name="hamburger-menu" size="16" />
          Optional Scope
        </BasketHeader>
        <div
          style={{
            display: 'inline-block',
            cursor: 'move',
            padding: '2px',
            minHeight: '10vh',
          }}>
          {equipment !== 'summary' &&
          groupedBasket.Optional &&
          equipment &&
          groupedBasket.Optional[equipment]
            ? Object.values(groupedBasket.Optional[equipment]).map(
                (equipmentId, index) => (
                  <OptionalCard
                    index={index}
                    basket={equipmentId}
                    deleteItem={deleteItem}
                    defaultBasket={defbasket}
                    setBasket={setBasket}
                    setUnsaved={setUnsaved}
                    isEditable={equipment !== "summary"}
                  />
                )
              )
            : null}
          {equipment === 'summary' && groupedBasket.Optional
            ? Object.keys(groupedBasket.Optional).map((equ) => (
                <div style={{ borderLeft: '2px solid #DEE1E5' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      backgroundColor: '#DEE1E5',
                      padding: '4px',
                    }}>
                    {equ}
                  </span>
                  {Object.values(groupedBasket.Optional[equ]).map(
                    (equipmentId, index) => (
                      <OptionalCard
                        index={index}
                        basket={equipmentId}
                        deleteItem={deleteItem}
                        defaultBasket={defbasket}
                        setBasket={setBasket}
                        setUnsaved={setUnsaved}
                        isEditable={equipment !== "summary"}
                      />
                    )
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
);
