import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { IBasket } from './Configurator.types';

export const ItemTypes = {
  ONSITE: 'Onsite',
  WORKSHOP: 'Workshop',
  OPTIONAL: 'Optional',
};

export const Disclaimer = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  line-height: 1.6em;
  color: #737473;
  font-weight: 400;
`;

export const Info = styled.span`
  border: 2px solid black;
  border-radius: 15px;
  padding: 3px 7px 3px 7px;
  font-size: 12px;
  color: black;
  line-height: 1.6em;
  font-weight: 400;
`;

export const Wrapper = styled.div`
  padding: 7px;
`;

export const SelectionButton = styled.div<{ $selected: boolean }>`
  padding: 3px 7px 3px 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 6.5em;
  white-space: nowrap;
  margin-right: 5px;
  border-radius: 15px;
  font-size: 15px;
  cursor: pointer;
  ${(props) =>
    props.$selected
      ? `background-color: #143456; color: #FAFAFA`
      : `background-color: #E4E6ED; color: #143456`}
`;
export const ProductButton = styled.div<{
  $selected: boolean;
  $warning: boolean;
}>`
  padding: 3px 7px 3px 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 6.5em;
  white-space: nowrap;
  margin-right: 5px;
  font-size: 15px;
  background-color: ${(p) => (p.$selected ? '#143456' : '#E4E6ED')};
  color: ${(p) => (p.$selected ? '#FAFAFA' : '#143456')};
  border: ${(p) => (p.$warning ? `2px solid orange;` : ``)};
  cursor: pointer;
`;

export const SectionCard = styled.div<{
  $selected: boolean;
  $reqLoading: boolean;
}>`
  width: 32%;
  height: 40px;
  margin: 2px;
  padding: 2px 5px 2px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 6.5em;
  margin-right: 5px;
  font-size: 12px;
  border: 1px solid black;
  text-overflow: ellipsis;
  background-color: ${(p) => (p.$selected ? '#143456' : '#fff')};
  color: ${(p) => (p.$selected ? '#FAFAFA' : '#000')};
  cursor: ${(p) => (p.$reqLoading ? 'wait' : 'pointer')};
  opacity: ${(p) => (p.$reqLoading ? '0.4' : '1')};
`;

export const SectionWrap = styled.div`
  margin-top: 15px;
  height: 100%;
  max-height: 150px; //change here
  overflow: auto;
`;

export const SelectButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled.div`
  height: 100px;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fff 8%, #f4f5f6 38%, #fff 54%);
  background-size: 800px 100px;

  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

export interface IStringIndex {
  [key: string]: {
    [key: string]: {
      [key: string]: IBasket[];
    };
  };
}

export const BasketHeader = styled.div`
  text-align: left;
  justify-content: left;
  background-color: #485470;
  color: #fff;
  padding: 0;
  display: flex;
  justify-items: center;
  font-size: 12px;
  line-height: 1.5rem;
`;

export const BasketRowHeader = styled.div`
  background-color: #6e7283;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  line-height: 1.5rem;
  width: 85vh;
  display: flex;
  font-size: 12px;
  justify-items: center;
  font-weight: lighter;
`;

export const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  max-width: 320px;
`;

export const useMounted = (): boolean => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted.current;
};
